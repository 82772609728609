import Heading from '@/components/common/Heading';
import Header from '@/components/global/Header';
import { NextPage } from 'next';
import React from 'react';

const PageNotFound: NextPage = () => {
    return (
        <div>
            <Header showSearch showSupportInfo />

            <section className="bg-secondary-100 m-md px-xl py-4xl text-center">
                <Heading level="2" noBottomMargin noTopMargin>
                    Sorry, we couldn’t find the page you’re looking for
                </Heading>
            </section>
        </div>
    );
};

export default PageNotFound;
